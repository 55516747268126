import { __awaiter } from "tslib";
import readyState from 'ready-state';
import LazyLoad from '@slickdeals/blueprint-twig/javascript/core/lazyLoad';
import '@slickdeals/blueprint-twig/javascript/patterns/dealCardGrid';
import '@slickdeals/blueprint-twig/javascript/patterns/dealContentListModule';
import '@slickdeals/blueprint-twig/javascript/patterns/storeLink';
import '@slickdeals/blueprint-twig/javascript/patterns/storeCard';
import '@slickdeals/blueprint-twig/javascript/components/cardCarousel';
import '../patterns/latestArticles';
import '../patterns/cashbackTag';
import '../patterns/sidebarDeals';
import '../patterns/trendingStores';
import '../patterns/showNewDealsButton';
import '../patterns/socialDealCard';
import '../patterns/inlineResponse';
import './standalonePageEntrypointBanner';
import '../recipes/holidaySeoBlock';
import '../patterns/cashbackTagActivationModal';
import AdRefreshEvents from "../utilities/adRefreshEvents";
import { initPage } from '../utilities/initPage';
/** The name of the module */
export const name = 'Frontpage';
initPage();
readyState.domready.then(() => __awaiter(void 0, void 0, void 0, function* () {
    const lazyLoad = new LazyLoad();
    const mobileDealFeedContainer = document.querySelector('[data-module-name="Unified Feed"]');
    lazyLoad.setObserver(mobileDealFeedContainer);
    if (window['adRefreshStrategy'] === 'mobile') {
        (new AdRefreshEvents()).initializeAdRefreshOnUpscroll();
    }
    if (window.dataLayer.traffic.deviceSegment === 'mobile') {
        window.addEventListener('pageshow', (event) => {
            var _a, _b, _c, _d;
            if (!navigator.sendBeacon || !performance.getEntriesByType) {
                return;
            }
            const [lastNavigation] = performance.getEntriesByType('navigation');
            if (!((_a = lastNavigation === null || lastNavigation === void 0 ? void 0 : lastNavigation.type) === null || _a === void 0 ? void 0 : _a.startsWith('back_forward'))) {
                return;
            }
            const vitals = {
                'hit': event.persisted,
                'missReasons': (_d = (_c = (_b = lastNavigation.notRestoredReasons) === null || _b === void 0 ? void 0 : _b.reasons) === null || _c === void 0 ? void 0 : _c.map(({ reason }) => reason)) !== null && _d !== void 0 ? _d : [],
            };
            navigator.sendBeacon('/web-api/web-vitals/bfcache/', JSON.stringify(vitals));
        });
    }
}));
