var ShowNewDealsButton_1;
import { __awaiter, __decorate } from "tslib";
// totally ripped from seleteButton.ts so far
import { injectable } from '@slickdeals/durango/dist/javascript/decorators/injectable';
import BlueprintComponent from '@slickdeals/blueprint-twig/javascript/core/blueprintComponent';
import Mediator from '@slickdeals/blueprint-twig/javascript/core/mediator';
import { mediatable } from '@slickdeals/blueprint-twig/javascript/decorators/mediatable';
import Button from '@slickdeals/blueprint-twig/javascript/components/button';
import '@slickdeals/blueprint-twig/javascript/components/icon';
import '../../scss/patterns/showNewDealsButton.scss';
/** The name of the module */
export const name = 'ShowNewDealsButton';
/** The CSS name of this module */
export const CSS_CLASSNAME = 'bp-p-showNewDealsButton';
/** The CSS selector for use in selector lookups in scripts */
export const JS_SELECTOR = 'js-showNewDealsButton';
/** Mediated events triggered by this module */
export var MediatedEvents;
(function (MediatedEvents) {
    MediatedEvents["Click"] = "click";
    MediatedEvents["ToggleShow"] = "toggleVisibility";
})(MediatedEvents || (MediatedEvents = {}));
let ShowNewDealsButton = ShowNewDealsButton_1 = class ShowNewDealsButton extends Button {
    /**
       * Create a ShowNewDealsButton
       * @param {Options} options Options to initialize the component with
       */
    constructor(options) {
        super(name, options);
    }
    /**
       * Create an instance of this module
       * @param {Options} options Options to initialize the component with
       */
    static create(options) {
        return new ShowNewDealsButton_1(options);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.init.call(this);
            this.element = yield BlueprintComponent.loadElement(name, this.options);
            this.isHovering = false;
            this.isVisible = false;
            document.addEventListener(Mediator.getNamespacedEvent(name, MediatedEvents.ToggleShow), this.toggleVisibility.bind(this));
            this.resolveButtonPositionState();
        });
    }
    resolveButtonPositionState() {
        // If the button's parent is 44px or less below the top of the viewport, switch to position: fixed
        if (!this.isHovering && this.element.parentElement.getBoundingClientRect().top < 44) {
            this.element.classList.add(`${CSS_CLASSNAME}_hovering`);
            this.isHovering = true;
        }
        if (this.isHovering && this.element.parentElement.getBoundingClientRect().top > 44) {
            this.element.classList.remove(`${CSS_CLASSNAME}_hovering`);
            this.isHovering = false;
        }
    }
    toggleVisibility() {
        const namespacedButtonClickEventName = Mediator.getNamespacedEvent(name, MediatedEvents.Click);
        const clickCallback = () => {
            document.dispatchEvent(new Event(namespacedButtonClickEventName));
        };
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            this.element.addEventListener(MediatedEvents.Click, clickCallback, { once: true });
            document.addEventListener('scroll', this.resolveButtonPositionState.bind(this));
            this.resolveButtonPositionState();
            this.element.classList.remove(`${CSS_CLASSNAME}_invisible`);
        }
        else {
            document.removeEventListener('scroll', this.resolveButtonPositionState.bind(this));
            this.element.classList.add(`${CSS_CLASSNAME}_invisible`);
        }
    }
};
ShowNewDealsButton = ShowNewDealsButton_1 = __decorate([
    injectable(name),
    mediatable(name, Object.values(MediatedEvents))
], ShowNewDealsButton);
export default ShowNewDealsButton;
// Create ShowNewDealsButton modules in the hydration queue
BlueprintComponent.instantiate(name);
